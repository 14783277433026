/**
 * Created by piotr.pozniak@thebeaverhead.com on 25/02/2019.
 */

import React, { useState } from "react";
import PropTypes from "prop-types";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import ApiError from "../../component/ApiError";
import FormError from "../../component/FormError";
import BillingDetailsForm from "../../component/BillingDetailsForm";
import { useSubscriptionStore } from "../../../../hooks/redux/subscription";
import { AffiliateCouponProps } from "../../component/Pricing";
import { useCurrentOrganizationStore } from "../../../../hooks/redux/currentOrganization";
import appConfig from "../../../../appConfig";

const BillingForm = (props) => {
  const { currentOrganization } = useCurrentOrganizationStore();
  const { subscription, initiateSubscription } = useSubscriptionStore();
  const organizationModel = currentOrganization.model;
  const organizationCredits = currentOrganization.credits;

  const stripe = useStripe();
  const elements = useElements();

  const [billingDetails, setBillingDetails] = useState({
    billing_name: organizationModel.billing_name || "",
    billing_contact: organizationModel.billing_contact || "",
    billing_address: organizationModel.billing_address || "",
    billing_country: organizationModel.billing_country || "",
    billing_city: organizationModel.billing_city || "",
    billing_state: organizationModel.billing_state || "",
    billing_zip: organizationModel.billing_zip || "",
    treasurer_email: organizationModel.treasurer_email || "",
  });

  const [state, setState] = useState({
    nameError: null,
    brandingError: null,
    tosError: null,
    autoChargeError: null,
  });

  /**
   *
   */
  const submit = (ev) => {
    // organization clicked submit

    ev.preventDefault();

    let nameError = null;
    let emailError = null;
    let tosError = null;
    let brandingError = null;
    let autoChargeError = null;

    if (billingDetails.billing_name.length <= 3) {
      nameError = "Please provide company name.";
    }

    const agreeTOS = document.getElementById("agree_tos").checked;
    const agreeBranding = document.getElementById("agree_branding");
    const agreeAutoCharge = document.getElementById("agree_auto_charge");

    if (!agreeTOS) {
      tosError = "Please accept Terms and Conditions.";
    }

    if (agreeBranding && !agreeBranding.checked) {
      brandingError = `Please agree to ${appConfig.shortDisplayName} branding.`;
    }

    if (agreeAutoCharge && !agreeAutoCharge.checked) {
      autoChargeError = "Please agree to auto charge.";
    }

    if (nameError || tosError || brandingError || autoChargeError) {
      setState({
        ...state,
        nameError,
        tosError,
        brandingError,
        autoChargeError,
      });
      return;
    }

    const billingData = {
      billing_name: billingDetails.billing_name,
      billing_contact: billingDetails.billing_contact,
      address_line1: billingDetails.billing_address,
      address_city: billingDetails.billing_city,
      address_state: billingDetails.billing_state,
      address_zip: billingDetails.billing_zip,
      address_country: billingDetails.billing_country.length
        ? billingDetails.billing_country
        : "USA",
    };

    setState({
      ...state,
      nameError: null,
      brandingError: null,
      tosError: null,
    });

    const cardElement = elements.getElement(CardElement);

    if (props.onSubmit) {
      return props.onSubmit(stripe, billingData);
    } else {
      initiateSubscription(
        stripe,
        cardElement,
        billingData,
        props.planUUID,
        props.isYearly,
        props.couponCode,
        props.referralCoupon,
        props.selectedPlan
      );
    }

    return false;
  };

  /**
   *
   */
  const onClose = () => {
    setTimeout(props.onClose, 350);
  };

  /**
   *
   * @param field
   * @returns {Function}
   */
  const onSetValue = (field) => (e) => {
    const data = { ...state };
    data[field] = e.target.value;

    setState(data);
  };

  /**
   *
   * @param data
   */
  const onUpdateAddressValue = (data) => {
    setBillingDetails(data);
  };

  const disabled = props.disabled;

  const brandingAgreement =
    appConfig.branding === "dc" && props.isBranded ? (
      <div className="row">
        <div className="col-sm-12">
          <div className="text-center">
            <div className="checkbox">
              <label>
                <input
                  id="agree_branding"
                  type="checkbox"
                  name="agree_branding"
                />{" "}
                I agree to add {appConfig.shortDisplayName} branding.{" "}
                <a
                  href={`${appConfig.helpdeskAddress}/en/articles/5991210-pricing-branded-or-unbranded`}
                  target={"_blank-why-branding"}
                >
                  <i className={"material-icons icon-xs"}>info</i>
                </a>
              </label>
            </div>
          </div>
        </div>
      </div>
    ) : null;

  const tosAgreement = (
    <div className="row">
      <div className="col-sm-12 m-b-15">
        <div className="text-center">
          <div className="checkbox">
            <label>
              <input id="agree_tos" type="checkbox" name="agree_tos" /> I agree
              to the{" "}
              <a href={appConfig.tos_link} target={"_blank_tos"}>
                Terms and Conditions
              </a>
              .
            </label>
          </div>
        </div>
      </div>
    </div>
  );

  const failedPayment1 = Number.parseInt(
    organizationModel.sent_timestamp_renewal_failed_period_1
  );

  const autoChargeAgreement =
    (props.showAutoCharge && failedPayment1) || failedPayment1 ? (
      <div className="row">
        <div className="col-sm-12">
          <div className="text-center">
            <div className="checkbox">
              <label>
                <input
                  id="agree_auto_charge"
                  type="checkbox"
                  name="agree_auto_charge"
                  defaultChecked={true}
                />{" "}
                I agree to auto charge my card for due invoices{" "}
                {organizationCredits && organizationCredits.paymentDue ? (
                  <span className={"text-danger"}>
                    ($
                    {Number.parseFloat(
                      organizationCredits.paymentDue / 100
                    ).toFixed(2)}
                    )
                  </span>
                ) : null}
                .
              </label>
            </div>
          </div>
        </div>
      </div>
    ) : null;

  return (
    <form>
      <BillingDetailsForm
        organization={billingDetails}
        onSetValue={onUpdateAddressValue}
        disabled={disabled}
        billingNameError={state.nameError}
      />

      <div className="row">
        <div className="col-sm-12">
          <div className="form-group">
            <label>
              Credit or debit card <span className="text-danger">*</span>
            </label>
            <CardElement />
          </div>
        </div>
      </div>

      <div className="m-t-20 text-center">
        <ApiError
          error={subscription.updateError || subscription.updatePaymentError}
          defaultErrorMessage={
            "Could not activate subscription. Please try again"
          }
        />
        {brandingAgreement}

        {autoChargeAgreement}
        {tosAgreement}

        <FormError
          errors={[
            state.nameError,
            state.brandingError,
            state.tosError,
            state.autoChargeError,
          ]}
        />

        <div className="form-group">
          <button
            className="btn btn-primary btn-lg"
            onClick={submit}
            disabled={disabled}
          >
            {props.submitButtonLabel}
          </button>
        </div>
      </div>
    </form>
  );
};

BillingForm.defaultProps = {
  showCCForm: true,
  showAutoCharge: false,
  selectedPlan: null,
};

BillingForm.propTypes = {
  disabled: PropTypes.bool,
  submitButtonLabel: PropTypes.string,
  planUUID: PropTypes.string,
  isYearly: PropTypes.bool,
  showCCForm: PropTypes.bool.isRequired,
  couponCode: PropTypes.string,
  isBranded: PropTypes.bool,
  onSubmit: PropTypes.func,
  referralCoupon: AffiliateCouponProps,
  showAutoCharge: PropTypes.bool,
  selectedPlan: PropTypes.object,
};

export default BillingForm;
