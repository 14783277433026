/**
 * Created by piotr.pozniak@thebeaverhead.com on 05/01/2024
 */

import React, { useCallback, useEffect } from "react";
import ModalTemplate from "./ModalTemplate";
import { useUserStore } from "../../../hooks/redux/user";
import { useSubscriptionStore } from "../../../hooks/redux/subscription";
import LoadingIndicator from "../../calendar/components/LoadingIndicator";
import BillingForm from "./Subscribe/BillingForm";
import { useHistory } from "react-router-dom";
import ApiError from "../component/ApiError";
import { useAppStore } from "../../../hooks/redux/app";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
const stripePromise = loadStripe(process.env.STRIPE_PK_KEY);

const UpdateBillingDetailsModal = (props) => {
  const { clearErrors } = useAppStore();
  const { checkSession } = useUserStore();
  const { subscription, updateBillingDetails } = useSubscriptionStore();
  const { updatePayment, updatePaymentError } = subscription;

  const history = useHistory();

  useEffect(() => {
    return clearErrors;
  }, []);

  /**
   *
   */
  useEffect(() => {
    if (subscription.updatePaymentSuccess) {
      checkSession();
      history.push("/billing");
    }
  }, [subscription.updatePaymentSuccess]);

  /**
   *
   */
  const onSubmit = useCallback((stripe, billingData) => {
    updateBillingDetails(stripe, billingData);
  }, []);

  const loadingIndicator = updatePayment ? <LoadingIndicator /> : null;

  return (
    <ModalTemplate
      id={"billingDetailsModal"}
      header={"Update billing details"}
      size={"lg"}
    >
      <Elements stripe={stripePromise}>
        <BillingForm
          disabled={updatePayment ? true : false}
          submitButtonLabel={"Update"}
          showCoupon={false}
          defaultErrorMessage={
            "Could not update billing details. Please try again"
          }
          showAutoCharge={true}
          onSubmit={onSubmit}
        />
      </Elements>
      {loadingIndicator}
      <ApiError error={updatePaymentError} />
    </ModalTemplate>
  );
};

UpdateBillingDetailsModal.defaultProps = {};

UpdateBillingDetailsModal.propTypes = {};

export default UpdateBillingDetailsModal;
